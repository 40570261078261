import { isDesktop } from '~/utils/device'

export const UX_ASSETS = {
    'outdated.png': require('../../assets/ux/outdated.png'),
    'logo.png': require('../../assets/ux/logo.png'),
    'disconnected.png': require('../../assets/ux/disconnected.png'),
    'turn-blue.png': require('../../assets/ux/turn-blue.png'),
    'turn-red.png': require('../../assets/ux/turn-red.png'),
    'turn-vs.png': require('../../assets/ux/turn-vs.png'),

    // bgs
    'bg-button.png': require('../../assets/ux/bg-button.png'),
    'bg-pattern.png': require('../../assets/ux/bg-pattern.png'),
    'bg-weapons.png': require('../../assets/ux/bg-weapons.png'),
    'bg-weapons-white.png': require('../../assets/ux/bg-weapons-white.png'),
    'bg-diagonal.png': require('../../assets/ux/bg-diagonal.png'),
    'bg-diagonal2.png': require('../../assets/ux/bg-diagonal2.png'),
    'bg-gamelist.png': require('../../assets/ux/bg-gamelist.png'),
    'bg-reward.png': require('../../assets/ux/bg-reward.png'),
    'bg-reward2.png': require('../../assets/ux/bg-reward2.png'),
    'bg-reward3.png': require('../../assets/ux/bg-reward3.png'),
    'bg-bar.png': require('../../assets/ux/bg-bar.png'),
    'bg-rewardmilestone.png': require('../../assets/ux/bg-rewardmilestone.png'),
    'bg-rewardtrophies.png': require('../../assets/ux/bg-rewardtrophies.png'),
    'bg-shine.png': require('../../assets/ux/bg-shine.png'),
    'bg-shine2.png': require('../../assets/ux/bg-shine2.png'),
    'bg-timer-blue.png': require('../../assets/ux/bg-timer-blue.png'),
    'bg-timer-red.png': require('../../assets/ux/bg-timer-red.png'),
    'bg-game-header.png': require('../../assets/ux/bg-game-header.png'),
    'bg-game-footer.png': require('../../assets/ux/bg-game-footer.png'),
    'bg-game-parchment.png': require('../../assets/ux/bg-game-parchment.png'),
    'bg-game-parchment2.png': require('../../assets/ux/bg-game-parchment2.png'),
    'bg-searching.png': require('../../assets/ux/bg-searching.png'),
    'bg-game-winner-bg.png': require('../../assets/ux/bg-game-winner-bg.png'),
    'bg-game-winner-victory.png': require('../../assets/ux/bg-game-winner-victory.png'),
    'bg-game-winner-defeat.png': require('../../assets/ux/bg-game-winner-defeat.png'),
    'bg-game-winner-draw.png': require('../../assets/ux/bg-game-winner-draw.png'),

    // icon
    'icon-back.png': require('../../assets/ux/icon-back.png'),
    'icon-close.png': require('../../assets/ux/icon-close.png'),
    'icon-shop.png': require('../../assets/ux/icon-shop.png'),
    'icon-play.png': require('../../assets/ux/icon-play.png'),
    'icon-cards.png': require('../../assets/ux/icon-cards.png'),
    'icon-cards2.png': require('../../assets/ux/icon-cards2.png'),
    'icon-tulip.png': require('../../assets/ux/icon-tulip.png'),
    'icon-coin.png': require('../../assets/ux/icon-coin.png'),
    'icon-dollar.png': require('../../assets/ux/icon-dollar.png'),
    'icon-trophy.png': require('../../assets/ux/icon-trophy.png'),
    'icon-trophy2.png': require('../../assets/ux/icon-trophy2.png'),
    'icon-friends.png': require('../../assets/ux/icon-friends.png'),
    'icon-settings.png': require('../../assets/ux/icon-settings.png'),
    'icon-settings2.png': require('../../assets/ux/icon-settings2.png'),
    'icon-loading.png': require('../../assets/ux/icon-loading.png'),
    'icon-copy.png': require('../../assets/ux/icon-copy.png'),
    'icon-edit.png': require('../../assets/ux/icon-edit.png'),
    'icon-check.png': require('../../assets/ux/icon-check.png'),
    'icon-check-green.png': require('../../assets/ux/icon-check-green.png'),
    'icon-check-input-dark.png': require('../../assets/ux/icon-check-input-dark.png'),
    'icon-check-input-light.png': require('../../assets/ux/icon-check-input-light.png'),
    'icon-drop-light.png': require('../../assets/ux/icon-drop-light.png'),
    'icon-drop-dark.png': require('../../assets/ux/icon-drop-dark.png'),
    'icon-check-off.png': require('../../assets/ux/icon-check-off.png'),
    'icon-check-on.png': require('../../assets/ux/icon-check-on.png'),
    'icon-wallet.png': require('../../assets/ux/icon-wallet.png'),
    'icon-power.png': require('../../assets/ux/icon-power.png'),
    'icon-clock.png': require('../../assets/ux/icon-clock.png'),
    'icon-discord.png': require('../../assets/ux/icon-discord.png'),
    'icon-user.png': require('../../assets/ux/icon-user.png'),
    'icon-user2.png': require('../../assets/ux/icon-user2.png'),
    'icon-player-blue.png': require('../../assets/ux/icon-player-blue.png'),
    'icon-player-red.png': require('../../assets/ux/icon-player-red.png'),
    'icon-unit-blue.png': require('../../assets/ux/icon-unit-blue.png'),
    'icon-unit-red.png': require('../../assets/ux/icon-unit-red.png'),
    'icon-flag-blue.png': require('../../assets/ux/icon-flag-blue.png'),
    'icon-flag-red.png': require('../../assets/ux/icon-flag-red.png'),
    'icon-xp.png': require('../../assets/ux/icon-xp.png'),
    'icon-maintenance.png': require('../../assets/ux/icon-maintenance.png'),
    'icon-penalty.png': require('../../assets/ux/icon-penalty.png'),

    // units-icon
    'unit-icon-white-knight.png': require('../../assets/ux/unit-icon-white-knight.png'),
    'unit-icon-white-spearman.png': require('../../assets/ux/unit-icon-white-spearman.png'),
    'unit-icon-white-axeman.png': require('../../assets/ux/unit-icon-white-axeman.png'),
    'unit-icon-white-archer.png': require('../../assets/ux/unit-icon-white-archer.png'),
    'unit-icon-white-slinger.png': require('../../assets/ux/unit-icon-white-slinger.png'),
    'unit-icon-white-crossbowoman.png': require('../../assets/ux/unit-icon-white-crossbowoman.png'),

    // header
    'header-bg.png': require('../../assets/ux/header-bg.png'),
    'header-profile-shadow.png': require('../../assets/ux/header-profile-shadow.png'),
    // footer
    'footer-bg.png': require('../../assets/ux/footer-bg.png'),
    'footer-separator.png': require('../../assets/ux/footer-separator.png'),
    // dialog
    'dialog-bg-login.png': require('../../assets/ux/dialog-bg-login.png'),
    'dialog-bg-shop.png': require('../../assets/ux/dialog-bg-shop.png'),
    'dialog-title.png': require('../../assets/ux/dialog-title.png'),
    'dialog-left.png': require('../../assets/ux/dialog-left.png'),
    'dialog-right.png': require('../../assets/ux/dialog-right.png'),
    // button
    'button-bg.png': require('../../assets/ux/button-bg.png'),
    'button-rewards-right.png': require('../../assets/ux/button-rewards-right.png'),
    'button-rewards-left.png': require('../../assets/ux/button-rewards-left.png'),
    // input
    'input-bg.png': require('../../assets/ux/input-bg.png'),
    // play
    'play-option-tutorial.png': require('../../assets/ux/play-option-tutorial.png'),
    'play-option-ranked.png': require('../../assets/ux/play-option-ranked.png'),
    'play-option-custom.png': require('../../assets/ux/play-option-custom.png'),
    // animations
    'animation-knight.png': require('../../assets/ux/animation-knight.png'),
    // cards
    'card-power.png': require('../../assets/ux/card-power.png'),
    'card-count.png': require('../../assets/ux/card-count.png'),
    'card-shadow.png': require('../../assets/ux/card-shadow.png'),
    'card-deck-center.png': require('../../assets/ux/card-deck-center.png'),
    'card-deck-left.png': require('../../assets/ux/card-deck-left.png'),
    'card-deck-right.png': require('../../assets/ux/card-deck-right.png'),

    // shop
    'shop-item-background.png': require('../../assets/ux/shop-item-background.png'),
    'shop-off.png': require('../../assets/ux/shop-off.png'),

    // recruit
    'unit-recruit-knight.png': require('../../assets/ux/unit-recruit-knight.png'),
    'unit-recruit-spearman.png': require('../../assets/ux/unit-recruit-spearman.png'),
    'unit-recruit-axeman.png': require('../../assets/ux/unit-recruit-axeman.png'),
    'unit-recruit-archer.png': require('../../assets/ux/unit-recruit-archer.png'),
    'unit-recruit-slinger.png': require('../../assets/ux/unit-recruit-slinger.png'),
    'unit-recruit-crossbowoman.png': require('../../assets/ux/unit-recruit-crossbowoman.png'),
}

export const PROFILE_ICONS = {
    0: require('../../assets/ux/icons/icon0.png'),
    1: require('../../assets/ux/icons/icon1.png'),
    2: require('../../assets/ux/icons/icon2.png'),
    3: require('../../assets/ux/icons/icon3.png'),
    4: require('../../assets/ux/icons/icon4.png'),
    5: require('../../assets/ux/icons/icon5.png'),
    6: require('../../assets/ux/icons/icon6.png'),
    7: require('../../assets/ux/icons/icon7.png'),
    8: require('../../assets/ux/icons/icon8.png'),
    9: require('../../assets/ux/icons/icon9.png'),
    10: require('../../assets/ux/icons/icon10.png'),
    11: require('../../assets/ux/icons/icon11.png'),
    12: require('../../assets/ux/icons/icon12.png'),
    13: require('../../assets/ux/icons/icon13.png'),
    14: require('../../assets/ux/icons/icon14.png'),
    15: require('../../assets/ux/icons/icon15.png'),
    16: require('../../assets/ux/icons/icon16.png'),
    17: require('../../assets/ux/icons/icon17.png'),
    18: require('../../assets/ux/icons/icon18.png'),
    19: require('../../assets/ux/icons/icon19.png'),
    20: require('../../assets/ux/icons/icon20.png'),
}

export const CARD_ASSETS = {
    //common
    '1adrenaline': require('../../assets/cards/1adrenaline.png'),
    '1downwind': require('../../assets/cards/1downwind.png'),
    '1holyberries': require('../../assets/cards/1holyberries.png'),
    '1poisonedarrow': require('../../assets/cards/1poisonedarrow.png'),
    '1woodshield': require('../../assets/cards/1woodshield.png'),
    '1projectilebounce': require('../../assets/cards/1projectilebounce.png'),
    '1poisonantidote': require('../../assets/cards/1poisonantidote.png'),
    //uncommon
    '2adrenaline': require('../../assets/cards/2adrenaline.png'),
    '2downwind': require('../../assets/cards/2downwind.png'),
    '2holyberries': require('../../assets/cards/2holyberries.png'),
    '2poisonedarrow': require('../../assets/cards/2poisonedarrow.png'),
    '2woodshield': require('../../assets/cards/2woodshield.png'),
    '2projectilebounce': require('../../assets/cards/2projectilebounce.png'),
    '2poisonantidote': require('../../assets/cards/2poisonantidote.png'),
    //rare
    '3adrenaline': require('../../assets/cards/3adrenaline.png'),
    '3downwind': require('../../assets/cards/3downwind.png'),
    '3holyberries': require('../../assets/cards/3holyberries.png'),
    '3poisonedarrow': require('../../assets/cards/3poisonedarrow.png'),
    '3woodshield': require('../../assets/cards/3woodshield.png'),
    '3projectilebounce': require('../../assets/cards/3projectilebounce.png'),
    '3poisonantidote': require('../../assets/cards/3poisonantidote.png'),
    //epic
    '4adrenaline': require('../../assets/cards/4adrenaline.png'),
    '4downwind': require('../../assets/cards/4downwind.png'),
    '4holyberries': require('../../assets/cards/4holyberries.png'),
    '4poisonedarrow': require('../../assets/cards/4poisonedarrow.png'),
    '4woodshield': require('../../assets/cards/4woodshield.png'),
    '4projectilebounce': require('../../assets/cards/4projectilebounce.png'),
    '4poisonantidote': require('../../assets/cards/4poisonantidote.png'),
    //legendary
    '5adrenaline': require('../../assets/cards/5adrenaline.png'),
    '5downwind': require('../../assets/cards/5downwind.png'),
    '5holyberries': require('../../assets/cards/5holyberries.png'),
    '5poisonedarrow': require('../../assets/cards/5poisonedarrow.png'),
    '5woodshield': require('../../assets/cards/5woodshield.png'),
    '5projectilebounce': require('../../assets/cards/5projectilebounce.png'),
    '5poisonantidote': require('../../assets/cards/5poisonantidote.png'),
}

export const THREE_ASSETS = {
    'pattern_tiledetails.png': require('../../assets/three/pattern_tiledetails.png'),
    'flags.png': require('../../assets/three/flags.png'),
    'tile-flag.png': require('../../assets/three/tile-flag.png'),
    'tiles.png': require('../../assets/three/tiles.png'),
    'tile0.png': require('../../assets/three/tile0.png'),
    'tile2.png': require('../../assets/three/tile2.png'),
    'tile3.png': require('../../assets/three/tile3.png'),
    'tile-range.png': require('../../assets/three/tile-range.png'),
    'tile-attack.png': require('../../assets/three/tile-attack.png'),
    'tile-walk.png': require('../../assets/three/tile-walk.png'),
    'tile-over-blue.png': require('../../assets/three/tile-over-blue.png'),
    'tile-over-red.png': require('../../assets/three/tile-over-red.png'),
    'tile-drag-blue.png': require('../../assets/three/tile-drag-blue.png'),
    'tile-drag-red.png': require('../../assets/three/tile-drag-red.png'),
    'tile-circle.png': require('../../assets/three/tile-circle.png'),
    'tile-doublecircle.png': require('../../assets/three/tile-doublecircle.png'),

    'grass1.png': require('../../assets/three/grass1.png'),
    'grass2.png': require('../../assets/three/grass2.png'),
    'grass3.png': require('../../assets/three/grass3.png'),
    'flowers1.png': require('../../assets/three/flowers1.png'),
    'flowers2.png': require('../../assets/three/flowers2.png'),
    'sand1.png': require('../../assets/three/sand1.png'),
    'sand2.png': require('../../assets/three/sand2.png'),
    'sand3.png': require('../../assets/three/sand3.png'),
    'sand4.png': require('../../assets/three/sand4.png'),
    'water1.png': require('../../assets/three/water1.png'),
    'stone1.png': require('../../assets/three/stone1.png'),
    'stone3.png': require('../../assets/three/stone3.png'),
    'stone4.png': require('../../assets/three/stone4.png'),
    'stone5.png': require('../../assets/three/stone5.png'),
    'tree1.png': require('../../assets/three/tree1.png'),
    'tree2.png': require('../../assets/three/tree2.png'),
    'tree3.png': require('../../assets/three/tree3.png'),
    'hand.png': require('../../assets/three/hand.png'),
    'hand-circle.png': require('../../assets/three/hand-circle.png'),
    'line-walk.png': require('../../assets/three/line-walk.png'),
    'line-attack.png': require('../../assets/three/line-attack.png'),
    'icons-shadow.png': require('../../assets/three/icons-shadow.png'),
    'icons/card_adrenaline.png': require('../../assets/three/icons/card_adrenaline.png'),
    'icons/card_downwind.png': require('../../assets/three/icons/card_downwind.png'),
    'icons/card_poisonedarrow.png': require('../../assets/three/icons/card_poisonedarrow.png'),
    'icons/card_poisonedarrow2.png': require('../../assets/three/icons/card_poisonedarrow2.png'),
    'icons/card_woodshield.png': require('../../assets/three/icons/card_woodshield.png'),
    'icons/card_projectilebounce.png': require('../../assets/three/icons/card_projectilebounce.png'),
    'numberhit-0.png': require('../../assets/three/numberhit-0.png'),
    'numberhit-1.png': require('../../assets/three/numberhit-1.png'),
    'numberhit-2.png': require('../../assets/three/numberhit-2.png'),
    'numberhit-3.png': require('../../assets/three/numberhit-3.png'),
    'numberhit-4.png': require('../../assets/three/numberhit-4.png'),
    'numberhit-5.png': require('../../assets/three/numberhit-5.png'),
    'numberhealth-1.png': require('../../assets/three/numberhealth-1.png'),
    'numberhealth-2.png': require('../../assets/three/numberhealth-2.png'),
    'numberhealth-3.png': require('../../assets/three/numberhealth-3.png'),
    'numberhealth-4.png': require('../../assets/three/numberhealth-4.png'),
    'unit-hit.png': require('../../assets/three/unit-hit.png'),
    'unit-death.png': require('../../assets/three/unit-death.png'),
    'unit-shadow.png': require('../../assets/three/unit-shadow.png'),
    'unit-life-blue3.png': require('../../assets/three/unit-life-blue3.png'),
    'unit-life-blue4.png': require('../../assets/three/unit-life-blue4.png'),
    'unit-life-blue5.png': require('../../assets/three/unit-life-blue5.png'),
    'unit-life-red3.png': require('../../assets/three/unit-life-red3.png'),
    'unit-life-red4.png': require('../../assets/three/unit-life-red4.png'),
    'unit-life-red5.png': require('../../assets/three/unit-life-red5.png'),

    'units/archer-blue.png': isDesktop()
        ? require('../../assets/three/units/archer-blue.png')
        : require('../../assets/three/units/mobile/archer-blue.png'),
    'units/archer-red.png': isDesktop()
        ? require('../../assets/three/units/archer-red.png')
        : require('../../assets/three/units/mobile/archer-red.png'),
    'units/axeman-blue.png': isDesktop()
        ? require('../../assets/three/units/axeman-blue.png')
        : require('../../assets/three/units/mobile/axeman-blue.png'),
    'units/axeman-red.png': isDesktop()
        ? require('../../assets/three/units/axeman-red.png')
        : require('../../assets/three/units/mobile/axeman-red.png'),
    'units/crossbowoman-blue.png': isDesktop()
        ? require('../../assets/three/units/crossbowoman-blue.png')
        : require('../../assets/three/units/mobile/crossbowoman-blue.png'),
    'units/crossbowoman-red.png': isDesktop()
        ? require('../../assets/three/units/crossbowoman-red.png')
        : require('../../assets/three/units/mobile/crossbowoman-red.png'),
    'units/knight-blue.png': isDesktop()
        ? require('../../assets/three/units/knight-blue.png')
        : require('../../assets/three/units/mobile/knight-blue.png'),
    'units/knight-red.png': isDesktop()
        ? require('../../assets/three/units/knight-red.png')
        : require('../../assets/three/units/mobile/knight-red.png'),
    'units/slinger-blue.png': isDesktop()
        ? require('../../assets/three/units/slinger-blue.png')
        : require('../../assets/three/units/mobile/slinger-blue.png'),
    'units/slinger-red.png': isDesktop()
        ? require('../../assets/three/units/slinger-red.png')
        : require('../../assets/three/units/mobile/slinger-red.png'),
    'units/spearman-blue.png': isDesktop()
        ? require('../../assets/three/units/spearman-blue.png')
        : require('../../assets/three/units/mobile/spearman-blue.png'),
    'units/spearman-red.png': isDesktop()
        ? require('../../assets/three/units/spearman-red.png')
        : require('../../assets/three/units/mobile/spearman-red.png'),
}

export const FONTS = {
    ChangaOne: require('../../assets/fonts/ChangaOne-Regular.ttf'),
    'Poppins-Medium': require('../../assets/fonts/Poppins-Medium.ttf'),
    'Poppins-SemiBold': require('../../assets/fonts/Poppins-SemiBold.ttf'),
    'Poppins-Bold': require('../../assets/fonts/Poppins-Bold.ttf'),
    'Poppins-Black': require('../../assets/fonts/Poppins-Black.ttf'),
}

export const AUDIOS = {
    // UX
    CLICK: require(`../../assets/audio/click.wav`),
    // CLICK2: require(`../../assets/audio/click2.wav`),
    // CLICK3: require(`../../assets/audio/click3.mp3`),
    OPEN: require(`../../assets/audio/open.mp3`),
    CLOSE: require(`../../assets/audio/close.mp3`),
    // SELECT: require(`../../assets/audio/select.wav`),
    REWARD: require(`../../assets/audio/reward.mp3`),
    TIMER: require(`../../assets/audio/timer.mp3`),
    // NOTIFICATION: require(`../../assets/audio/notification.mp3`),
    MATCH_FOUND: require(`../../assets/audio/match_found.mp3`),
    SHOP: require(`../../assets/audio/shop.mp3`),

    // GAMEPLAY
    MUSIC: require(`../../assets/audio/music.mp3`),
    TURN_START: require(`../../assets/audio/turn_start.mp3`),
    TURN_END: require(`../../assets/audio/turn_end.mp3`),
    CARD: require(`../../assets/audio/card.mp3`),
    UNIT_SELECT: require(`../../assets/audio/unit_select.mp3`),
    UNIT_UNSELECT: require(`../../assets/audio/unit_unselect.mp3`),
    WALK: require(`../../assets/audio/walk.mp3`),
    ATTACK_RANGE: require(`../../assets/audio/attack_range.mp3`),
    ATTACK_MELEE: require(`../../assets/audio/attack_melee.mp3`),
    DEATH: require(`../../assets/audio/death.mp3`),
    VICTORY: require(`../../assets/audio/victory.mp3`),
    DEFEAT: require(`../../assets/audio/defeat.mp3`),
}
