import React, { useEffect } from 'react'
import styled from '@emotion/native'
import dop, { applyPatch } from 'dop'
import {
    Board,
    Actions,
    EVENT,
    TEAM,
    PLAYER,
    CARDS,
    GAME_EVENT,
    GAME_ACTION,
    SERVER_METHOD,
    USER_STATUS,
} from 'conkis-core'
import { convertEventNameToFunction } from 'conkis-core/src/utils/index'
import { AUDIO } from '~/audio'
import { translate } from '~/locale'
import { getGlobalState } from '~/store'
import { Server, serverNow } from '~/server'
import { TILE_COLORS } from '~/const'
import Canvas from 'components/views/Game/Canvas'
import Ux from 'components/views/Game/Ux'

export default function Logic({ emitter, game, user, onReload }) {
    const board = Board({ state: game.board, dateNow: serverNow })
    const actions = Actions({ board, dop })

    const state = {
        // general
        card: null,
        card_selected: {},
        //canvas
        hand_timer: Infinity,
        recruiting: null,
        unit_selected: null,
        unit_selected_last: null,
        //ux
        message: null,
        confirm: null,
        cancel: null,
        show_winner: false,
        card_activation: null,
        //static
        get spectator() {
            return user.status.type === USER_STATUS.NONE
        },
        get player_id() {
            return user.status.player_id || PLAYER.PLAYER_1
        },
        get team_id() {
            return board.getTeamByPlayer({ player_id: state.player_id })
        },
        get can_action() {
            return board.getTurnStatusByPlayer({ player_id: state.player_id })
                .can_action
        },
    }

    useEffect(() => {
        const { music } = getGlobalState()
        if (music === true) {
            AUDIO.MUSIC(true)
        }

        const interval = setInterval(() => {
            emitter.emit(GAME_EVENT.HAND_INTERVAL, {})
        }, 1000)

        return () => {
            AUDIO.MUSIC(false)
            clearInterval(interval)
        }
    }, [])

    emitter.on(EVENT.GAME_ACTION, (reactions) => {
        game.history.push(reactions)
        actions.applyReactions(reactions)
        reactions.forEach((reaction) => {
            emitter.emit(GAME_EVENT.REACTION, reaction)
        })
        emitter.emit(GAME_EVENT.UPDATE_UNITS_ACTIONS, {})

        // console.log(EVENT.GAME_ACTION, state.card, reactions)
        // if (state.card !== null) {
        //     state.card.update()
        // }
    })

    emitter.on(EVENT.GAME_INTERACTION, ({ type, player_id, params }) => {
        if (player_id !== state.player_id) {
            emitter.emit(type, { player_id, ...params })
        }
    })

    emitter.on(GAME_EVENT.SEND_ACTION, ({ type, params = {} }) => {
        const method = convertEventNameToFunction(type)
        const { game_id } = board.state
        Server[method]({ game_id, ...params })
    })

    emitter.on(GAME_EVENT.SEND_INTERACTION, ({ type, params }) => {
        const { player_id } = state
        const { game_id } = board.state
        if (state.can_action) {
            Server.gameInteraction({ game_id, player_id, type, params })
        }
        emitter.emit(type, { player_id, ...params })
    })

    emitter.on(GAME_EVENT.REACTION, (reaction) => {
        emitter.emit(reaction.type, reaction)
    })

    emitter.on(GAME_EVENT.THREE_READY, () => {
        emitter.emit(GAME_EVENT.LOAD_HISTORY, { history: game.history })
    })

    emitter.on(GAME_EVENT.LOAD_HISTORY, ({ history }) => {
        history.forEach(({ reactions = [] }) => {
            reactions.forEach((reaction) => {
                if (
                    [
                        GAME_ACTION.SET_BOARD,
                        GAME_ACTION.FLAG_ADD,
                        GAME_ACTION.FLAG_CAPTURED,
                        GAME_ACTION.UNIT_ADD,
                        GAME_ACTION.UNIT_CHANGE_TILE,
                        GAME_ACTION.UNIT_LIFE,
                        GAME_ACTION.UNIT_REMOVE,
                        GAME_ACTION.UNIT_ICON_ADD,
                        GAME_ACTION.UNIT_ICON_REMOVE,
                    ].includes(reaction.type)
                ) {
                    emitter.emit(GAME_EVENT.REACTION, {
                        ...reaction,
                        history: true,
                    })
                }
            })
        })
        emitter.emit(GAME_EVENT.UPDATE_UNITS_ACTIONS, {})
        emitter.emit(GAME_EVENT.HAND_HIDE, {})
    })

    // STATE
    // STATE
    // STATE
    // STATE

    emitter.on(GAME_EVENT.SET_STATE, (newstate) => {
        applyPatch(state, newstate)
    })

    emitter.on(GAME_EVENT.MESSAGE_SET, ({ message }) => {
        emitter.emit(GAME_EVENT.SET_STATE, { message: translate(message) })
    })
    emitter.on(GAME_EVENT.MESSAGE_UNSET, () => {
        emitter.emit(GAME_EVENT.SET_STATE, { message: null })
    })

    // TOUCH
    // TOUCH
    // TOUCH
    // TOUCH
    // TOUCH
    // TOUCH

    if (!state.spectator) {
        emitter.on(GAME_EVENT.MOUSE_MOVE, (params) => {
            const tile_id = board.positionToTile(params.position)
            const { player_id } = state
            emitter.emit(GAME_EVENT.TOUCH_MOVE, params)
            emitter.emit(GAME_EVENT.TILE_OVER, { tile_id, player_id })
            // emitter.emit(GAME_EVENT.SEND_INTERACTION, {
            //     type: GAME_EVENT.TILE_OVER,
            //     params: { tile_id },
            // })
        })

        emitter.on(GAME_EVENT.TOUCH_START, ({ position, e }) => {
            if (state.card === null) {
                const tile_id = board.positionToTile(position)
                const unit_id = board.getUnitByTile({ tile_id })
                const { player_id } = state

                // RECRUITING
                if (state.recruiting?.tile_id === tile_id) {
                    emitter.emit(GAME_EVENT.RECRUITING_DRAGGING, {
                        dragging: true,
                    })
                    return false
                }

                // SELECT
                else if (
                    unit_id !== undefined &&
                    state.can_action &&
                    board.isUnitPlayer({ unit_id, player_id }) &&
                    board.getUnitAvailability({ unit_id }).available
                ) {
                    // UNSELECT
                    if (state.unit_selected !== null) {
                        emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
                    }

                    // SELECT
                    emitter.emit(GAME_EVENT.UNIT_SELECT, { unit_id })
                    return false
                }

                return true
            }
        })

        emitter.on(
            GAME_EVENT.TOUCH_MOVE,
            ({ position, isDragged, isTouch }) => {
                const tile_id = board.positionToTile(position)
                const unit_id = board.getUnitByTile({ tile_id })

                // RECRUITING
                if (state.recruiting?.dragging === true) {
                    const tiles_team = board.getTilesCanRecruit({
                        player_id: state.player_id,
                    })
                    if (
                        board.isHabitableAndWalkableTile({ tile_id }) &&
                        tiles_team.includes(tile_id)
                    ) {
                        emitter.emit(GAME_EVENT.SET_STATE, {
                            recruiting: { tile_id: tile_id },
                        })
                        emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                            type: GAME_EVENT.RECRUITING_MOVE,
                            params: { tile_id },
                        })
                    }
                }

                // SELECTED UNIT
                else if (state.unit_selected !== null) {
                    const unit_id = state.unit_selected
                    const unit = board.state.units[unit_id]
                    const { can_walk, can_attack } = board.getUnitAvailability({
                        unit_id,
                    })

                    emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                        type: GAME_EVENT.PATH_WALK_REMOVE,
                        params: {},
                    })
                    emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                        type: GAME_EVENT.PATH_ATTACK_REMOVE,
                        params: {},
                    })

                    // WALK INDICATORS
                    if (can_walk) {
                        if (
                            // tile_id === unit.tile_id ||
                            board.isValidTileToWalk({ unit_id, tile_id }) &&
                            (!isTouch || (isTouch && isDragged))
                        ) {
                            emitter.emit(GAME_EVENT.HAND_HIDE, {})

                            emitter.emit(GAME_EVENT.TILES_RANGE_VISIBLE, {
                                unit_id,
                                visible: true,
                            })
                            emitter.emit(GAME_EVENT.TILES_RANGE_MOVE, {
                                unit_id,
                                tile_id,
                            })
                            emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                                type: GAME_EVENT.PATH_WALK_CREATE,
                                params: {
                                    tile_id_from: unit.tile_id,
                                    tile_id_to: tile_id,
                                },
                            })

                            return
                        } else {
                            emitter.emit(GAME_EVENT.TILES_RANGE_MOVE, {
                                unit_id,
                                tile_id: unit.tile_id,
                            })
                        }
                    }

                    // ATTACK INDICATORS
                    if (can_attack) {
                        const enemy = board.isValidTileToAttackWithMovement({
                            unit_id,
                            tile_id,
                        })

                        if (board.isValidTileToAttack({ unit_id, tile_id })) {
                            emitter.emit(GAME_EVENT.HAND_HIDE, {})

                            emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                                type: GAME_EVENT.PATH_ATTACK_CREATE,
                                params: {
                                    tile_id_from: unit.tile_id,
                                    tile_id_to: tile_id,
                                },
                            })
                        } else if (enemy !== undefined) {
                            const tile_id_walk = enemy.tiles[0].tile_id
                            emitter.emit(GAME_EVENT.HAND_HIDE, {})
                            emitter.emit(GAME_EVENT.TILES_RANGE_MOVE, {
                                unit_id,
                                tile_id: tile_id_walk,
                            })
                            emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                                type: GAME_EVENT.PATH_WALK_CREATE,
                                params: {
                                    tile_id_from: unit.tile_id,
                                    tile_id_to: tile_id_walk,
                                },
                            })
                            emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                                type: GAME_EVENT.PATH_ATTACK_CREATE,
                                params: {
                                    tile_id_from: tile_id_walk,
                                    tile_id_to: tile_id,
                                },
                            })
                        }
                    }
                }
            }
        )

        emitter.on(GAME_EVENT.TOUCH_CLICK, (params) => {
            const tile_id = board.positionToTile(params.position)
            // console.log(tile_id)
            emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                type: GAME_EVENT.TILE_OVER,
                params: { tile_id },
            })
            emitter.emit(GAME_EVENT.TOUCH_ACTION, params)
        })

        emitter.on(GAME_EVENT.TOUCH_DRAGGED, (params) => {
            emitter.emit(GAME_EVENT.TOUCH_ACTION, params)
        })

        emitter.on(GAME_EVENT.TOUCH_ACTION, ({ position, isClicked }) => {
            if (state.card === null) {
                const tile_id = board.positionToTile(position)

                // RECRUITING_DRAGGING
                if (state.recruiting?.dragging === true) {
                    emitter.emit(GAME_EVENT.RECRUITING_DRAGGING, {
                        dragging: false,
                    })
                }

                // UNIT SELECTED
                else if (state.unit_selected !== null) {
                    const unit_id = state.unit_selected

                    // WALK
                    if (board.isValidTileToWalk({ unit_id, tile_id })) {
                        emitter.emit(GAME_EVENT.SEND_ACTION, {
                            type: SERVER_METHOD.GAME_UNIT_WALK,
                            params: { unit_id, tile_id },
                        })
                        emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
                        return
                    }

                    // ATTACK
                    if (board.isValidTileToAttack({ unit_id, tile_id })) {
                        emitter.emit(GAME_EVENT.SEND_ACTION, {
                            type: SERVER_METHOD.GAME_UNIT_ATTACK,
                            params: { unit_id, tile_id },
                        })
                        emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
                        return
                    }

                    // WALK & ATTACK
                    const enemy = board.isValidTileToAttackWithMovement({
                        unit_id,
                        tile_id,
                    })
                    if (
                        board.isValidTileToAttackWithMovement({
                            unit_id,
                            tile_id,
                        })
                    ) {
                        emitter.emit(GAME_EVENT.SEND_ACTION, {
                            type: SERVER_METHOD.GAME_UNIT_WALK,
                            params: {
                                unit_id,
                                tile_id: enemy.tiles[0].tile_id,
                            },
                        })
                        emitter.emit(GAME_EVENT.SEND_ACTION, {
                            type: SERVER_METHOD.GAME_UNIT_ATTACK,
                            params: { unit_id, tile_id },
                        })
                        emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
                        return
                    }

                    // UNSELECT UNIT
                    if (
                        isClicked &&
                        tile_id !== board.getTileByUnit({ unit_id })
                    ) {
                        AUDIO.UNIT_UNSELECT()
                        emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
                        return
                    }
                }
            }
        })
    }

    // END TOUCH
    // END TOUCH
    // END TOUCH

    emitter.on(GAME_EVENT.UNIT_WALK_ENDED, ({ unit_id }) => {
        const { player_id } = state

        if (
            unit_id !== undefined &&
            unit_id === state.unit_selected_last &&
            board.getTurnStatusByPlayer({ player_id }).can_action &&
            board.isUnitPlayer({ unit_id, player_id }) &&
            board.getUnitAvailability({ unit_id }).available
        ) {
            // console.log('UNIT_SELECT', { unit_id })
            emitter.emit(GAME_EVENT.UNIT_SELECT, { unit_id })
        }
    })

    emitter.on(GAME_EVENT.UNIT_ATTACK_ENDED, ({ unit_id }) => {
        const { can_action } = board.getTurnStatusByPlayer({
            player_id: state.player_id,
        })
        if (can_action && state.unit_selected === unit_id) {
            emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
        }
    })

    emitter.on(
        GAME_EVENT.RECRUITING_START,
        ({ player_id, unit_type, tile_id }) => {
            if (player_id === state.player_id) {
                emitter.emit(GAME_EVENT.MESSAGE_SET, {
                    message: 'Drag and confirm position',
                })

                emitter.emit(GAME_EVENT.SET_STATE, {
                    recruiting: { unit_type, tile_id },
                    confirm: () => {
                        emitter.emit(GAME_EVENT.SEND_ACTION, {
                            type: SERVER_METHOD.GAME_RECRUIT,
                            params: state.recruiting,
                        })
                    },
                    cancel: () => {
                        emitter.emit(GAME_EVENT.SEND_INTERACTION, {
                            type: GAME_EVENT.RECRUITING_CANCEL,
                            params: {},
                        })
                    },
                })

                if (state.unit_selected !== null) {
                    emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
                }
            }
        }
    )

    emitter.on(GAME_EVENT.RECRUITING_CANCEL, () => {
        emitter.emit(GAME_EVENT.MESSAGE_UNSET, {})
        emitter.emit(GAME_EVENT.SET_STATE, {
            recruiting: null,
            confirm: null,
            cancel: null,
        })
        if (state.unit_selected !== null) {
            emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
        }
    })

    emitter.on(GAME_EVENT.RECRUITING_DRAGGING, ({ dragging }) => {
        emitter.emit(GAME_EVENT.SET_STATE, {
            recruiting: { dragging },
        })
    })

    emitter.on(GAME_EVENT.UNIT_SELECT, ({ unit_id }) => {
        AUDIO.UNIT_SELECT()

        // RANGE TILES
        emitter.emit(GAME_EVENT.TILES_RANGE_CREATE, { unit_id })

        const { walk, attack } = board.getUnitActions({ unit_id })

        if (walk.length > 0) {
            emitter.emit(GAME_EVENT.TILES_WALK_SHOW, { tiles: walk })
        }

        if (attack.length > 0) {
            const tiles_units = attack.map(({ tile_id, bonus }) => ({
                tile_id,
                color: bonus ? TILE_COLORS.ATTACK_BONUS : TILE_COLORS.ATTACK,
            }))
            emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_SHOW, {
                tiles: tiles_units,
            })
        }

        emitter.emit(GAME_EVENT.SET_STATE, {
            unit_selected: unit_id,
            unit_selected_last: unit_id,
        })
        emitter.emit(GAME_EVENT.UPDATE_UNITS_ACTIONS, {})
        emitter.emit(GAME_EVENT.HAND_HIDE, {})
    })

    emitter.on(GAME_EVENT.UNIT_UNSELECT, () => {
        const unit_id = state.unit_selected
        emitter.emit(GAME_EVENT.SET_STATE, {
            unit_selected: null,
        })
        emitter.emit(GAME_EVENT.SEND_INTERACTION, {
            type: GAME_EVENT.PATH_WALK_REMOVE,
            params: {},
        })
        emitter.emit(GAME_EVENT.SEND_INTERACTION, {
            type: GAME_EVENT.PATH_ATTACK_REMOVE,
            params: {},
        })
        emitter.emit(GAME_EVENT.UNIT_UNSELECTED, { unit_id })
        emitter.emit(GAME_EVENT.TILES_WALK_HIDE_ALL, {})
        emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_HIDE_ALL, {})
        emitter.emit(GAME_EVENT.TILES_RANGE_REMOVE, { unit_id })
        emitter.emit(GAME_EVENT.UPDATE_UNITS_ACTIONS, {})
        emitter.emit(GAME_EVENT.HAND_HIDE, {})
    })

    // CARDS
    // CARDS
    // CARDS

    emitter.on(GAME_EVENT.CARD_SELECT, ({ player_id, card }) => {
        const patch = { card_selected: { [player_id]: card } }

        emitter.emit(GAME_EVENT.HIDE_ALL, {})

        if (player_id === state.player_id) {
            patch.card = CARDS[card.type].create({
                emitter,
                board,
                player_id,
            })
            patch.card.init()
        }

        emitter.emit(GAME_EVENT.SET_STATE, patch)
    })

    emitter.on(GAME_EVENT.CARD_UNSELECT, ({ player_id }) => {
        const patch = {
            card_selected: { [player_id]: dop.TYPE.Delete() },
        }

        if (player_id === state.player_id) {
            emitter.emit(GAME_EVENT.UPDATE_UNITS_ACTIONS, {})
            if (state.card !== null) {
                state.card.destroy()
                patch.card = null
            }
        }

        emitter.emit(GAME_EVENT.SET_STATE, patch)
    })

    emitter.on(GAME_EVENT.CARD_UNSELECT_ALL, () => {
        Object.keys(state.card_selected).forEach((player_id) => {
            emitter.emit(GAME_EVENT.CARD_UNSELECT, { player_id })
        })
    })

    emitter.on(GAME_EVENT.CARD_USING, (params) => {
        const { player_id } = state
        const { type, rarity } = state.card_selected[player_id]
        const { cards } = board.state.players[player_id]
        const card = cards.find(
            (c) =>
                c.used === undefined && c.type === type && c.rarity === rarity
        )
        emitter.emit(GAME_EVENT.SEND_ACTION, {
            type: SERVER_METHOD.GAME_CARD_USE,
            params: { card_id: card._id, params },
        })
    })

    emitter.on(GAME_EVENT.CARD_CANCEL, () => {
        emitter.emit(GAME_EVENT.SEND_INTERACTION, {
            type: GAME_EVENT.CARD_UNSELECT,
            params: {},
        })
    })

    emitter.on(GAME_EVENT.HIDE_ALL, () => {
        emitter.emit(GAME_EVENT.HIDE_TILES_OVER, {})
        emitter.emit(GAME_EVENT.RECRUITING_CANCEL_ALL, {})
        emitter.emit(GAME_EVENT.PATH_WALK_REMOVE_ALL, {})
        emitter.emit(GAME_EVENT.PATH_ATTACK_REMOVE_ALL, {})
        emitter.emit(GAME_EVENT.HAND_HIDE, {})
        if (state.unit_selected !== null) {
            emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
        }
    })

    if (!state.spectator) {
        emitter.on(GAME_EVENT.HAND_INTERVAL, () => {
            const { player_id } = state

            if (
                state.can_action &&
                Date.now() - state.hand_timer > 5000 &&
                !board.isValidToRecruit({ player_id })
            ) {
                // CARD
                if (state.card_selected.hasOwnProperty(player_id)) {
                    // console.log('HAND HELPER CARD')
                    return
                }

                // SELECT
                if (state.unit_selected === null) {
                    const units = board
                        .getUnitAvailabilityByPlayer({ player_id })
                        .filter(({ available }) => available)

                    // testAttackAll(units, board, emitter)

                    units.sort((a, b) => a.can_walk - b.can_walk)
                    if (units.length > 0) {
                        const [{ unit_id }] = units
                        emitter.emit(GAME_EVENT.HAND_SHOW, {
                            tile_id: board.getTileByUnit({ unit_id }),
                            color: TILE_COLORS.GREEN,
                        })
                    }
                } else {
                    const { walk, attack } = board.getUnitActions({
                        unit_id: state.unit_selected,
                    })

                    // ATTACK
                    if (attack.length > 0) {
                        emitter.emit(GAME_EVENT.HAND_SHOW, {
                            tile_id: attack[0].tile_id,
                            color: TILE_COLORS.WHITE,
                        })
                    }

                    // WALK
                    else if (walk.length > 0) {
                        const tile_id_center = board.getTileCenter()
                        const tile_id_unit =
                            board.state.units[state.unit_selected].tile_id
                        const distances = walk
                            .map((tile_id) => ({
                                tile_id,
                                tile_id_center,
                                tile_id_unit,
                                distance: board.getMathDistanceFromTiles({
                                    tile_id1: tile_id,
                                    tile_id2: tile_id_center,
                                }),
                                distance2: board.getMathDistanceFromTiles({
                                    tile_id1: tile_id,
                                    tile_id2: tile_id_unit,
                                }),
                            }))
                            .sort((a, b) => a.distance2 - b.distance2)
                            .sort((a, b) => a.distance - b.distance)

                        emitter.emit(GAME_EVENT.HAND_SHOW, {
                            tile_id: distances[0].tile_id,
                            color: TILE_COLORS.BLUE,
                        })
                    }
                }
            }
        })

        emitter.on(GAME_EVENT.HAND_SHOW, () => {
            emitter.emit(GAME_EVENT.SET_STATE, {
                hand_timer: Infinity,
            })
        })

        emitter.on(GAME_EVENT.HAND_HIDE, () => {
            emitter.emit(GAME_EVENT.SET_STATE, {
                hand_timer: Date.now(),
            })
        })
    }

    emitter.on(GAME_EVENT.WINNED, () => {
        emitter.emit(GAME_EVENT.SET_STATE, { show_winner: true })
        emitter.emit(GAME_EVENT.HIDE_ALL, {})
        emitter.removeAll()
    })

    // GAME_ACTION
    // GAME_ACTION
    // GAME_ACTION
    // GAME_ACTION
    // GAME_ACTION

    emitter.on(GAME_ACTION.TURN_START, ({ params }) => {
        const { team_id } = params
        let tiles = board
            .getUnitsByTeam({ team_id })
            .map((unit_id) => board.state.units[unit_id].tile_id)

        if (tiles.length === 0) {
            tiles = board.getTilesFromSquare(board.state.teams[team_id].tiles)
        }

        emitter.emit(GAME_EVENT.HIDE_TILES_OVER, {})
        emitter.emit(GAME_EVENT.RECRUITING_CANCEL_ALL, {})
        emitter.emit(GAME_EVENT.CARD_UNSELECT_ALL, {})
        emitter.emit(GAME_EVENT.PATH_WALK_REMOVE_ALL, {})
        emitter.emit(GAME_EVENT.PATH_ATTACK_REMOVE_ALL, {})
        emitter.emit(GAME_EVENT.HAND_HIDE, {})

        if (state.unit_selected !== null) {
            emitter.emit(GAME_EVENT.UNIT_UNSELECT, {})
        }

        if (team_id === board.getTeamByPlayer({ player_id: state.player_id })) {
            AUDIO.TURN_START()
            emitter.emit(GAME_EVENT.CAMERA_POSITION_TILES, { tiles })
        }
    })

    emitter.on(GAME_ACTION.TURN_END, ({ params }) => {
        if (params.player_id === state.player_id) {
            AUDIO.TURN_END()
        }
    })

    emitter.on(GAME_ACTION.RECRUIT, ({ params }) => {
        AUDIO.UNIT_SELECT()
        emitter.emit(GAME_EVENT.RECRUITING_CANCEL, {
            player_id: params.player_id,
        })
    })

    emitter.on(GAME_ACTION.CARD_USE, ({ params }) => {
        AUDIO.CARD()
        emitter.emit(GAME_EVENT.CARD_UNSELECT, { player_id: params.player_id })
        emitter.emit(GAME_EVENT.CARD_USED, params)
    })

    return (
        <Container>
            <Canvas emitter={emitter} board={board} state={state} />
            <Ux
                emitter={emitter}
                board={board}
                state={state}
                onReload={onReload}
            />
        </Container>
    )
}

const Container = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
`

// function testAttackAll(units, board, emitter) {
//     units.forEach(({ unit_id, available }) => {
//         const tile_id = board.getTileByUnit({ unit_id })
//         if (available) {
//             const { walk, attack } = board.getUnitActions({ unit_id })
//             if (attack.length > 0) {
//                 const tile = attack[0].tiles[0]
//                 if (tile.tile_id !== tile_id) {
//                     emitter.emit(GAME_EVENT.SEND_ACTION, {
//                         type: SERVER_METHOD.GAME_UNIT_WALK,
//                         params: { unit_id, tile_id: tile.tile_id },
//                     })
//                 }
//                 emitter.emit(GAME_EVENT.SEND_ACTION, {
//                     type: SERVER_METHOD.GAME_UNIT_ATTACK,
//                     params: { unit_id, tile_id: attack[0].tile_id },
//                 })
//             } else if (walk.length > 0) {
//                 const [tile_id] = board.getTilesByDistance({
//                     tiles: walk,
//                     tile_id: board.getTileCenter(),
//                 })
//                 emitter.emit(GAME_EVENT.SEND_ACTION, {
//                     type: SERVER_METHOD.GAME_UNIT_WALK,
//                     params: { unit_id, tile_id },
//                 })
//             }
//         }
//     })
// }
