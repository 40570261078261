import React, { useState, useEffect } from 'react'
import styled from '@emotion/native'
import {
    EVENT,
    GAME_EVENT,
    GAME_ACTION,
    SERVER_METHOD,
    UNIT_TYPE,
    COLOR_NAME,
    COLOR_PLAYER_CODE,
    CARDS,
    TEAM,
    GAME_TYPE,
} from 'conkis-core'
import { getDeck } from 'conkis-core/src/utils/cards'
import { px, pxRaw, isWeb, screenWidth } from '~/utils/device'
import { AUDIO } from '~/audio'
import { translate } from '~/locale'
import { cardToAsset, msToSeconds, secondsToTime } from '~/utils'
import { useTimer } from '~/store/hooks'
import { COLOR, CARD_SIZE, PADDING } from '~/const'
import { UX_ASSETS, CARD_ASSETS } from '~/const/assets'
import {
    AnimationPulsate,
    AnimationBlink,
    AnimationSlideInOut,
    AnimationFadeOut,
    AnimationVibrate,
} from '~/components/Animations'
import SettingsGame from '~/components/dialogs/SettingsGame'
import GamePlayerCards from '~/components/dialogs/GamePlayerCards'
import CardInfo from '~/components/dialogs/CardInfo'
import Timer from '~/components/stateless/Timer'
import Button from '~/components/stateless/Button'
import Deck from '~/components/stateless/Deck'
import Card from '~/components/stateless/Card'
import Counter from '~/components/stateless/Counter'
import Winner from '~/components/views/Game/Winner'

const DECK_SCALE = 0.7
const BUTTON_WIDTH = 325
const BUTTON_HEIGHT = 80
const BUTTON_FONTSIZE = 35
const COUNTER_SCALE = 0.9

export default function Ux({ emitter, board, state, onReload }) {
    // Computed values
    const team_id_blue = state.team_id
    const team_id_red =
        state.team_id === TEAM.TEAM_1 ? TEAM.TEAM_2 : TEAM.TEAM_1
    const blues = formatTeamData({ team_id: team_id_blue, board, state })
    const reds = formatTeamData({ team_id: team_id_red, board, state })
    const me = blues[0]
    const show_confirmcancel = state.confirm !== null || state.cancel !== null
    const { winner } = board.state
    const starting = board.state.turn === 0
    const unit_actions = board
        .getUnitAvailabilityByPlayer({ player_id: state.player_id })
        .filter((unit) => me.can_action && unit.available)
    const card_selected = state.card_selected[me.player_id]
    const timer = {
        player: me.is_turn_team ? blues[0] : reds[0],
        color: me.is_turn_team ? COLOR_NAME.BLUE : COLOR_NAME.RED,
        board,
    }

    // UX State
    const [, forceUpdate] = useState({})
    const [showrecruit, setShowRecruit] = useState(false)
    const [showsettings, setShowSettings] = useState(false)
    const [deck, setDeck] = useState(getDeckAvailable(me.player_id)) // .concat(getDeck(cards)) .concat(getDeck(cards)).concat(getDeck(cards))
    const [showplayerdeck, setShowPlayerDeck] = useState(null)
    const [showcard, setShowCard] = useState(null)
    const [showturnanimation, setShowTurnAnimation] = useState(null)

    // Hooks
    useEffect(() => {
        emitter.on(EVENT.GAME_ACTION, forceUpdate)
        emitter.on(GAME_EVENT.RECRUITING_START, forceUpdate)
        emitter.on(GAME_EVENT.RECRUITING_CANCEL, forceUpdate)
        emitter.on(GAME_EVENT.CARD_SELECT, forceUpdate)
        emitter.on(GAME_EVENT.CARD_UNSELECT, forceUpdate)
        emitter.on(GAME_EVENT.CARD_UNSELECT_ALL, forceUpdate)
        emitter.on(GAME_EVENT.WINNED, forceUpdate)
        emitter.on(GAME_EVENT.CARD_USED, onCardActivation)
        emitter.on(GAME_ACTION.TURN_START, onTurnStart)
        emitter.on(GAME_ACTION.TURN_END, () => setShowRecruit(false))
        emitter.on(GAME_ACTION.FLAG_CAPTURED, ({ params }) => {
            const { unit_id } = params
            if (
                board.state.units.hasOwnProperty(unit_id) &&
                me.player_id === board.getPlayerByUnit({ unit_id })
            ) {
                updateDeck()
            }
        })
    }, [])

    // Ons
    function onEndTurn() {
        emitter.emit(GAME_EVENT.SEND_ACTION, {
            type: SERVER_METHOD.GAME_TURN_END,
        })
    }
    function onRecruitShowUnits() {
        AUDIO.CLICK()
        setShowRecruit(true)
    }
    function onRecruitUnit(unit_type) {
        AUDIO.CLICK()
        setShowRecruit(false)
        const [tile_id] = board.getTilesCanRecruit({
            player_id: state.player_id,
        })
        emitter.emit(GAME_EVENT.SEND_INTERACTION, {
            type: GAME_EVENT.RECRUITING_START,
            params: { unit_type, tile_id },
        })
    }
    function onConfirm() {
        AUDIO.CLICK()
        state.confirm()
    }
    function onCancel() {
        AUDIO.CLOSE()
        state.cancel()
    }
    function onCardSelect(card) {
        AUDIO.CLICK()
        emitter.emit(GAME_EVENT.SEND_INTERACTION, {
            type: GAME_EVENT.CARD_SELECT,
            params: { card },
        })
    }
    function onCardUnselect() {
        AUDIO.CLOSE()
        emitter.emit(GAME_EVENT.CARD_CANCEL, {})
    }
    function onCardActivation(params) {
        if (params.player_id === me.player_id && !state.spectator) {
            updateDeck()
        }

        // Animation card used by the enemy
        else {
            const { player_id, card_id } = params
            const player = formatPlayerData({ board, player_id, state })
            const card = player.cards.find((c) => c._id === card_id)
            emitter.emit(GAME_EVENT.SET_STATE, {
                card_activation: { player, card },
            })
            setTimeout(() => {
                emitter.emit(GAME_EVENT.SET_STATE, { card_activation: null })
                forceUpdate({})
            }, 3000)
            forceUpdate({})
        }
    }
    function onCloseSettings() {
        AUDIO.CLOSE()
        setShowSettings(false)
    }
    function onResign() {
        emitter.emit(GAME_EVENT.SEND_ACTION, {
            type: SERVER_METHOD.GAME_RESIGN,
        })
        setShowSettings(false)
    }
    function onPlayerStats(player_id) {
        setShowPlayerDeck(player_id)
    }
    function onTurnStart({ params }) {
        const direction = params.team_id === me.team_id ? -1 : 1
        setShowTurnAnimation(direction)
    }

    // Helpers
    function updateDeck() {
        setDeck(getDeckAvailable(me.player_id))
    }
    function getDeckAvailable(player_id) {
        const { cards, power } = board.state.players[player_id]
        const deck = getDeck(
            cards.filter((card) => card.used === undefined),
            power
        )
        return deck
    }

    return starting ? (
        <Starting blues={blues} reds={reds} />
    ) : (
        <>
            <Header>
                <HeaderFooterBackground
                    source={UX_ASSETS['bg-game-header.png']}
                    imageStyle={{ resizeMode: 'stretch' }}
                />

                <HeaderContent hide={state.message !== null}>
                    <HeaderSide>
                        <PlayerStats
                            player={blues[0]}
                            board={board}
                            onPlayerStats={onPlayerStats}
                        />
                    </HeaderSide>
                    <HeaderCenter>
                        {!starting && winner === null && <Timer {...timer} />}
                    </HeaderCenter>
                    <HeaderSide>
                        <PlayerStats
                            player={reds[0]}
                            board={board}
                            onPlayerStats={onPlayerStats}
                        />
                    </HeaderSide>
                </HeaderContent>
            </Header>

            {state.message !== null && (
                <MessageContainer top={50}>
                    <MessageBorder>
                        <Message>{state.message}</Message>
                    </MessageBorder>
                </MessageContainer>
            )}

            {winner === null && (
                <Footer>
                    <HeaderFooterBackground
                        source={UX_ASSETS['bg-game-footer.png']}
                        imageStyle={{ resizeMode: 'stretch' }}
                    />

                    {/* Card Selected */}
                    {card_selected !== undefined && (
                        <CardSelected>
                            <CardSelectedContent>
                                <CardSelectedCard>
                                    <Card
                                        touchable={false}
                                        power={card_selected.power}
                                        source={
                                            CARD_ASSETS[
                                                cardToAsset(card_selected)
                                            ]
                                        }
                                    />
                                </CardSelectedCard>
                                <CardSelectedTitle>
                                    {translate(CARDS[card_selected.type].title)}
                                </CardSelectedTitle>
                                <CardSelectedDescription>
                                    {translate(
                                        CARDS[card_selected.type].description
                                    )}
                                </CardSelectedDescription>
                            </CardSelectedContent>
                            <CardSelectedButton>
                                <Button
                                    color={COLOR.RED}
                                    width={BUTTON_WIDTH / 1.2}
                                    height={BUTTON_HEIGHT / 1.2}
                                    fontSize={BUTTON_FONTSIZE / 1.2}
                                    label={translate('Cancel')}
                                    onClick={onCardUnselect}
                                />
                            </CardSelectedButton>
                        </CardSelected>
                    )}

                    {/* Confirm Cancel Buttons */}
                    {show_confirmcancel && (
                        <FooterConfirmCancel>
                            {state.confirm && (
                                <FooterConfirmCancelButton>
                                    <Button
                                        color={COLOR.BROWNDARK}
                                        width={BUTTON_WIDTH}
                                        height={BUTTON_HEIGHT}
                                        fontSize={BUTTON_FONTSIZE}
                                        label={translate('Confirm')}
                                        onClick={onConfirm}
                                    />
                                </FooterConfirmCancelButton>
                            )}
                            {state.cancel && (
                                <FooterConfirmCancelButton>
                                    <Button
                                        color={COLOR.RED}
                                        width={BUTTON_WIDTH}
                                        height={BUTTON_HEIGHT}
                                        fontSize={BUTTON_FONTSIZE}
                                        label={translate('Cancel')}
                                        onClick={onCancel}
                                    />
                                </FooterConfirmCancelButton>
                            )}
                        </FooterConfirmCancel>
                    )}

                    {!show_confirmcancel &&
                        card_selected === undefined &&
                        showturnanimation === null && (
                            <FooterContent>
                                {/* Settings Button */}
                                <FooterLeft>
                                    <Button
                                        color={COLOR.BROWNDARK}
                                        width={BUTTON_HEIGHT}
                                        height={BUTTON_HEIGHT}
                                        icon={'icon-settings2.png'}
                                        iconSize={40}
                                        onClick={() => {
                                            AUDIO.CLICK()
                                            setShowSettings(true)
                                        }}
                                    />
                                </FooterLeft>

                                {/* Deck */}
                                {!state.spectator && me.can_action && (
                                    <FooterCenter>
                                        <Deck
                                            cards={deck}
                                            scale={DECK_SCALE}
                                            shadow={false}
                                            onClick={onCardSelect}
                                        />
                                    </FooterCenter>
                                )}

                                <FooterRight>
                                    {!state.spectator &&
                                        me.can_recruit &&
                                        !showrecruit && (
                                            <AnimationPulsate>
                                                <Button
                                                    color={COLOR.REDPINK}
                                                    width={BUTTON_WIDTH}
                                                    height={BUTTON_HEIGHT}
                                                    fontSize={BUTTON_FONTSIZE}
                                                    label={translate('Recruit')}
                                                    onClick={onRecruitShowUnits}
                                                />
                                            </AnimationPulsate>
                                        )}

                                    {!state.spectator &&
                                        me.can_action &&
                                        !me.can_recruit && (
                                            <AnimationPulsate
                                                active={
                                                    unit_actions.length === 0
                                                }
                                            >
                                                <Button
                                                    color={COLOR.BROWNDARK}
                                                    width={'100%'}
                                                    height={75}
                                                    fontSize={32}
                                                    label={translate(
                                                        'End Turn'
                                                    )}
                                                    onClick={onEndTurn}
                                                />
                                            </AnimationPulsate>
                                        )}
                                </FooterRight>
                            </FooterContent>
                        )}
                </Footer>
            )}

            {showrecruit && (
                <Recruit
                    recruited={me.recruited}
                    units_player={board.state.units_player}
                    onRecruitUnit={onRecruitUnit}
                    onClose={() => {
                        AUDIO.CLICK()
                        setShowRecruit(false)
                    }}
                />
            )}

            {state.card_activation !== null && (
                <CardActivation>
                    <CardActivationMessage>
                        <MessageBorder>
                            <Message>
                                {translate('${username} has used ${type}', {
                                    type: translate(
                                        CARDS[state.card_activation.card.type]
                                            .title
                                    ),
                                    username:
                                        state.card_activation.player.username,
                                })}
                            </Message>
                        </MessageBorder>
                    </CardActivationMessage>

                    <Card
                        scale={1.5}
                        touchable={false}
                        power={state.card_activation.card.power}
                        source={
                            CARD_ASSETS[cardToAsset(state.card_activation.card)]
                        }
                    />
                </CardActivation>
            )}

            {showsettings && (
                <SettingsGame
                    state={state}
                    onResign={onResign}
                    onReload={onReload}
                    onClose={onCloseSettings}
                    onBackground={onCloseSettings}
                />
            )}

            {showplayerdeck !== null && (
                <GamePlayerCards
                    board={board}
                    player_id={showplayerdeck}
                    onCard={(card) => setShowCard(card)}
                    onClose={() => setShowPlayerDeck(null)}
                />
            )}

            {showcard !== null && (
                <CardInfo card={showcard} onClose={() => setShowCard(null)} />
            )}

            {state.show_winner && <Winner me={me} winner={winner} />}

            {showturnanimation !== null && (
                <Turn
                    state={state}
                    direction={showturnanimation}
                    onFinish={() => {
                        setShowTurnAnimation(null)
                    }}
                />
            )}
        </>
    )
}

function PlayerStats({ player, board, onPlayerStats }) {
    const HeaderColumn =
        player.color_team === COLOR_NAME.BLUE
            ? HeaderColumnLeft
            : HeaderColumnRight
    const HeaderRow =
        player.color_team === COLOR_NAME.BLUE ? HeaderRowLeft : HeaderRowRight
    const { flags_to_win } = board.state
    const flags = board.getFlagsByTeam({
        team_id: player.team_id,
    }).length

    return (
        <PlayerStatsContainer onPress={() => onPlayerStats(player.player_id)}>
            <HeaderRow>
                <PlayerContainer>
                    <Player>
                        <PlayerUsernameContainer>
                            <PlayerUsername>{player.username}</PlayerUsername>
                        </PlayerUsernameContainer>
                        {board.state.type === GAME_TYPE.COMPETITIVE && (
                            <PlayerTrophies>
                                <PlayerTrophiesIcon
                                    source={UX_ASSETS['icon-trophy2.png']}
                                />
                                <PlayerTrophiesValue>
                                    {player.trophies}
                                </PlayerTrophiesValue>
                            </PlayerTrophies>
                        )}

                        <PlayerIcon
                            source={
                                UX_ASSETS[
                                    `icon-player-${player.color_team.toLowerCase()}.png`
                                ]
                            }
                        />
                    </Player>
                </PlayerContainer>
            </HeaderRow>
            <HeaderRowSeparator />
            <HeaderRow>
                <HeaderColumn>
                    <Counter
                        value={player.units.length}
                        icon={`icon-unit-${player.color_team.toLowerCase()}.png`}
                        width={100}
                        scale={COUNTER_SCALE}
                        color={COLOR.BROWNBLACK}
                        color_text={'white'}
                        align="center"
                    />
                </HeaderColumn>
                <HeaderColumn>
                    <Counter
                        value={translate('${flags} of ${flags_to_win}', {
                            flags,
                            flags_to_win,
                        })}
                        icon={`icon-flag-${player.color_team.toLowerCase()}.png`}
                        width={135}
                        scale={COUNTER_SCALE}
                        color={COLOR.BROWNBLACK}
                        color_text={'white'}
                        align="center"
                        highlight={
                            player.color_team === COLOR_NAME.RED &&
                            flags_to_win - flags === 1
                                ? player.color_code
                                : null
                        }
                    />
                </HeaderColumn>
                <HeaderColumn>
                    <Counter
                        value={player.power}
                        icon="icon-power.png"
                        width={120}
                        scale={COUNTER_SCALE}
                        color={COLOR.BROWNBLACK}
                        color_text={'white'}
                        align="center"
                    />
                </HeaderColumn>
                <HeaderColumn>
                    <CounterClock player={player} board={board} />
                </HeaderColumn>
            </HeaderRow>
            <HeaderRowSeparator />
            {player.subs === 0 &&
                typeof player.abandon === 'number' &&
                typeof board.now === 'function' && (
                    <HeaderRow>
                        <AnimationBlink
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <PlayerDisconnected>
                                <PlayerDisconnectedText>
                                    {translate('Disconnected')}
                                    {': '}
                                    <TimerDisconnect
                                        now={board.now()}
                                        expires={player.abandon}
                                    />
                                </PlayerDisconnectedText>
                            </PlayerDisconnected>
                        </AnimationBlink>
                    </HeaderRow>
                )}
        </PlayerStatsContainer>
    )
}

function CounterClock({ player, board }) {
    const { available_bonus, available_timer, is_turn_team } =
        board.getTurnStatusByPlayer({
            player_id: player.player_id,
        })
    const bonus = is_turn_team && available_timer === 0
    // const delay = board.now() - Math.floor(board.now() / 1000) * 1000

    const [, forceUpdate] = useState()

    useEffect(() => {
        const timeout = setInterval(() => forceUpdate({}), 250)
        return () => clearInterval(timeout)
    }, [])

    return (
        <AnimationBlink active={bonus && board.state.winner === null}>
            <Counter
                value={msToSeconds(available_bonus)}
                icon="icon-clock.png"
                width={120}
                scale={COUNTER_SCALE}
                color={COLOR.BROWNBLACK}
                color_text={'white'}
                align="center"
            />
        </AnimationBlink>
    )
}

function Turn({ state, direction, onFinish }) {
    const screenwidth = Math.round(screenWidth())
    const color = direction === -1 ? 'blue' : 'red'
    const label =
        direction === -1
            ? state.spectator
                ? 'Blue Team'
                : 'Your Turn'
            : state.spectator
            ? 'Red Team'
            : 'Enemy Turn'
    const delay = 500
    const translateX = screenwidth * direction
    const keysbackground = [
        { value: 0, duration: 0 },
        { value: 1, duration: delay },
        { value: 1, duration: 750 },
        { value: 0, duration: 250 },
    ]
    const keysslide = [
        { value: 0, duration: 0 },
        { value: (translateX / 10) * -1, duration: 500 },
        { value: translateX, duration: 500 },
    ]
    return (
        <AnimationFadeOut
            keys={keysbackground}
            onFinish={onFinish}
            style={{
                pointerEvents: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#00000088',
            }}
        >
            <StartingTurnContent>
                <AnimationSlideInOut delay={delay} keys={keysslide}>
                    <TurnTeam color={color} label={translate(label)} />
                </AnimationSlideInOut>
            </StartingTurnContent>
        </AnimationFadeOut>
    )
}

function Starting({ blues, reds }) {
    const username1 = blues[0].username
    const username2 = reds[0].username
    return (
        <StartingTurnContainer>
            <StartingTurnContent>
                <AnimationVibrate offset={pxRaw(3)}>
                    <TurnTeam
                        color="blue"
                        label={username1}
                        scale={0.8}
                        scaletext={username1.length > 10 ? 0.7 : 1}
                    />
                </AnimationVibrate>
                <StartingCenter source={UX_ASSETS['turn-vs.png']} />
                <AnimationVibrate offset={pxRaw(3)} delay={100}>
                    <TurnTeam
                        color="red"
                        label={username2}
                        scale={0.8}
                        scaletext={username2.length > 10 ? 0.7 : 1}
                    />
                </AnimationVibrate>
            </StartingTurnContent>
            <StartingLoadingFooter>
                <AnimationBlink>
                    <StartingLoading>
                        <StartingLoadingText>
                            {translate('Loading')}...
                        </StartingLoadingText>
                    </StartingLoading>
                </AnimationBlink>
            </StartingLoadingFooter>
        </StartingTurnContainer>
    )
}

function TimerDisconnect({ now, expires }) {
    const [ms] = useTimer({
        initial: expires - now,
        increment: -1000,
    })
    const seconds = Math.round(ms / 1000)

    return (
        <PlayerDisconnectedText>
            {secondsToTime(seconds < 0 ? 0 : seconds)}
        </PlayerDisconnectedText>
    )
}

const StartingTurnContainer = styled.View`
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${'#000000' + '88'};
`
const StartingTurnContent = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`
const StartingCenter = styled.Image`
    width: ${px(250)};
    height: ${px(250)};
`
const StartingLoadingFooter = styled.View`
    position: absolute;
    width: 100%;
    bottom: 10%;
    align-items: center;
`
const StartingLoading = styled.View`
    width: ${px(400)};
    height: ${px(75)};
    border: ${px(5)} solid ${COLOR.BROWNBLACK};
    border-radius: ${px(5)};
    background: ${COLOR.BROWNBLACK};
    align-items: center;
    justify-content: center;
`
const StartingLoadingText = styled.Text`
    align-items: center;
    justify-content: center;
    color: white;
    font-size: ${px(30)};
    font-family: 'ChangaOne';
`

function TurnTeam({ color, label, scale = 1, scaletext = 1 }) {
    return (
        <TurnTeamContainer
            source={UX_ASSETS[`turn-${color}.png`]}
            scale={scale}
        >
            <TurnTeamLabel scale={scale} scaletext={scaletext}>
                {label}
            </TurnTeamLabel>
        </TurnTeamContainer>
    )
}

const TurnTeamContainer = styled.ImageBackground`
    width: ${(p) => px(p.scale * 1000)};
    height: ${(p) => px(p.scale * 450)};
    justify-content: center;
    align-items: center;
`
const TurnTeamLabel = styled.Text`
    font-size: ${(p) => px(p.scale * p.scaletext * 80)};
    font-family: ChangaOne;
    color: white;
    margin-right: ${(p) => px(p.scale * 40)};
`

function Recruit({ recruited, units_player, onRecruitUnit, onClose }) {
    const btnwidth = '95%'
    const btnheight = 55
    const btnfontsize = 28

    return (
        <RecruitBackground>
            <MessageContainer top={75}>
                <Message>
                    {translate(
                        'Recruit your unit ${recruited} of ${units_player}',
                        { recruited: recruited + 1, units_player }
                    )}
                </Message>
            </MessageContainer>

            <CloseContainer onPress={onClose}>
                <Close source={UX_ASSETS['icon-close.png']} />
            </CloseContainer>
            <RecruitUnits>
                <RecruitUnitContainer>
                    <RecruitUnit
                        source={UX_ASSETS['unit-recruit-knight.png']}
                    />
                    <RecruitButton
                        onPress={() => onRecruitUnit(UNIT_TYPE.KNIGHT)}
                    >
                        <Button
                            color={COLOR.BROWNBLACK}
                            width={btnwidth}
                            height={btnheight}
                            fontSize={btnfontsize}
                            label={'Knight'}
                            onClick={() => onRecruitUnit(UNIT_TYPE.KNIGHT)}
                        />
                    </RecruitButton>
                    <RecruitCounters>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-slinger.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-crossbowoman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-spearman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-axeman.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                    </RecruitCounters>
                </RecruitUnitContainer>
                <RecruitUnitContainer>
                    <RecruitUnit
                        source={UX_ASSETS['unit-recruit-spearman.png']}
                    />
                    <RecruitButton
                        onPress={() => onRecruitUnit(UNIT_TYPE.SPEARMAN)}
                    >
                        <Button
                            color={COLOR.BROWNBLACK}
                            width={btnwidth}
                            height={btnheight}
                            fontSize={btnfontsize}
                            label={'Spearman'}
                            onClick={() => onRecruitUnit(UNIT_TYPE.SPEARMAN)}
                        />
                    </RecruitButton>
                    <RecruitCounters>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-crossbowoman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-knight.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-axeman.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-archer.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                    </RecruitCounters>
                </RecruitUnitContainer>
                <RecruitUnitContainer>
                    <RecruitUnit
                        source={UX_ASSETS['unit-recruit-axeman.png']}
                    />
                    <RecruitButton
                        onPress={() => onRecruitUnit(UNIT_TYPE.AXEMAN)}
                    >
                        <Button
                            color={COLOR.BROWNBLACK}
                            width={btnwidth}
                            height={btnheight}
                            fontSize={btnfontsize}
                            label={'Axeman'}
                            onClick={() => onRecruitUnit(UNIT_TYPE.AXEMAN)}
                        />
                    </RecruitButton>
                    <RecruitCounters>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-knight.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-spearman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-archer.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-slinger.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                    </RecruitCounters>
                </RecruitUnitContainer>
                <RecruitUnitContainer>
                    <RecruitUnit
                        source={UX_ASSETS['unit-recruit-archer.png']}
                    />
                    <RecruitButton
                        onPress={() => onRecruitUnit(UNIT_TYPE.ARCHER)}
                    >
                        <Button
                            color={COLOR.BROWNBLACK}
                            width={btnwidth}
                            height={btnheight}
                            fontSize={btnfontsize}
                            label={'Archer'}
                            onClick={() => onRecruitUnit(UNIT_TYPE.ARCHER)}
                        />
                    </RecruitButton>
                    <RecruitCounters>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-spearman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-axeman.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-slinger.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-crossbowoman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                    </RecruitCounters>
                </RecruitUnitContainer>
                <RecruitUnitContainer>
                    <RecruitUnit
                        source={UX_ASSETS['unit-recruit-slinger.png']}
                    />
                    <RecruitButton
                        onPress={() => onRecruitUnit(UNIT_TYPE.SLINGER)}
                    >
                        <Button
                            color={COLOR.BROWNBLACK}
                            width={btnwidth}
                            height={btnheight}
                            fontSize={btnfontsize}
                            label={'Slinger'}
                            onClick={() => onRecruitUnit(UNIT_TYPE.SLINGER)}
                        />
                    </RecruitButton>
                    <RecruitCounters>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-axeman.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-archer.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-crossbowoman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-knight.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                    </RecruitCounters>
                </RecruitUnitContainer>
                <RecruitUnitContainer>
                    <RecruitUnit
                        source={UX_ASSETS['unit-recruit-crossbowoman.png']}
                    />
                    <RecruitButton
                        onPress={() => onRecruitUnit(UNIT_TYPE.CROSSBOWOMAN)}
                    >
                        <Button
                            color={COLOR.BROWNBLACK}
                            width={btnwidth}
                            height={btnheight}
                            fontSize={btnfontsize}
                            label={'Crossbowoman'}
                            onClick={() =>
                                onRecruitUnit(UNIT_TYPE.CROSSBOWOMAN)
                            }
                        />
                    </RecruitButton>
                    <RecruitCounters>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-archer.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={false}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-slinger.png']
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                        <RecruitCountersList>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS['unit-icon-white-knight.png']
                                    }
                                />
                            </RecruitCountersUnit>
                            <RecruitCountersUnit counter={true}>
                                <RecruitCountersIcon
                                    source={
                                        UX_ASSETS[
                                            'unit-icon-white-spearman.png'
                                        ]
                                    }
                                />
                            </RecruitCountersUnit>
                        </RecruitCountersList>
                    </RecruitCounters>
                </RecruitUnitContainer>
            </RecruitUnits>
        </RecruitBackground>
    )
}

function formatTeamData({ team_id, board, state }) {
    return Object.keys(board.state.players)
        .filter(
            (player_id) => board.state.players[player_id].team_id === team_id
        )
        .map((player_id, index) =>
            formatPlayerData({ board, player_id, state, index, team_id })
        )
}

function formatPlayerData({ board, player_id, state }) {
    const player = board.state.players[player_id]
    const turn = board.getTurnStatusByPlayer({
        player_id,
    })
    const color = board.getColorByPlayer({ player_id, me: state.player_id })
    const color_team = color.team
    const color_player = color.player
    const color_code = COLOR_PLAYER_CODE[color.player]

    return {
        ...player,
        ...turn,
        player_id,
        color_team,
        color_player,
        color_code,
        isme: player_id === state.player_id,
        can_recruit: turn.is_turn_team && board.isValidToRecruit({ player_id }),
        units: board.getUnitsByPlayer({ player_id }),
    }
}

const MessageContainer = styled.View`
    pointer-events: none;
    position: absolute;
    width: 100%;
    top: ${(p) => px(p.top)};
    height: ${px(100)};
    align-items: center;
    justify-content: center;
`
const MessageBorder = styled.View`
    background: ${COLOR.BLUEDARK + '44'};
    padding: ${px(15)} ${px(100)};
    border-radius: ${px(50)};
    backdrop-filter: blur(5px);
`
const Message = styled.Text`
    font-size: ${px(35)};
    font-family: ChangaOne;
    color: white;
    text-shadow: 0 ${px(3)} #00000099;
`
const HeaderFooterBackground = styled.ImageBackground`
    pointer-events: none;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
`

// Header
const PlayerStatsContainer = styled.TouchableOpacity``

const Header = styled.View`
    pointer-events: box-none;
    top: 0;
    width: 100%;
    position: absolute;
    height: ${px(CARD_SIZE.HEIGHT * DECK_SCALE)};
`
const HeaderContent = styled.View`
    pointer-events: box-none;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: ${px(PADDING.SIDES / 2)};
    padding-right: ${px(PADDING.SIDES / 2)};
    padding-top: ${px(PADDING.SIDES / 4)};
    flex-direction: row;
    opacity: ${(p) => String(p.hide ? 0 : 1)};
`
const HeaderSide = styled.View`
    pointer-events: box-none;
    width: ${px(500)};
    // flex: 1;
`
const HeaderCenter = styled.View`
    pointer-events: none;
    align-items: center;
    flex: 1;
`
const HeaderRowLeft = styled.View`
    flex-direction: row;
    justify-content: space-between;
`
const HeaderRowRight = styled.View`
    flex-direction: row;
    justify-content: space-between;
`
const HeaderRowSeparator = styled.View`
    height: ${px(15)};
`
const HeaderColumnLeft = styled.View``
const HeaderColumnRight = styled.View``

const PlayerContainer = styled.View`
    width: 100%;
    height: ${px(50)};
`
const Player = styled.View`
    border-radius: ${px(8)};
    background: ${COLOR.BROWNBLACK};
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const PlayerDisconnected = styled.View`
    width: 100%;
    height: ${px(50)};
    background: ${COLOR.RED};
    border: ${px(5)} solid ${COLOR.RED + 'CC'};
    border-radius: ${px(8)};
    align-items: center;
    justify-content: center;
`
const PlayerDisconnectedText = styled.Text`
    color: white;
    font-family: Poppins-Bold;
    font-size: ${px(20)};
`

const PlayerIcon = styled.Image`
    position: absolute;
    width: ${px(60)};
    height: ${px(60)};
    left: ${px(-30)};
    top: ${px(-5)};
`
const PlayerUsernameContainer = styled.View`
    flex: 1;
`
const PlayerUsername = styled.Text`
    ${isWeb() ? 'white-space: nowrap;' : ''}
    ${isWeb() ? 'text-overflow: ellipsis;' : ''}
    overflow: hidden;
    color: white;
    font-family: Poppins-Black;
    letter-spacing: ${px(1)};
    padding-left: ${px(40)};
    font-size: ${px(28)};
`
const PlayerTrophies = styled.View`
    flex-direction: row;
    align-items: center;
    padding-right: ${px(20)};
    margin-left: ${px(10)};
`
const PlayerTrophiesIcon = styled.Image`
    width: ${px(20)};
    height: ${px(20)};
`
const PlayerTrophiesValue = styled.Text`
    color: ${COLOR.BEIGE2};
    font-size: ${px(22)};
    font-family: Poppins-Bold;
    padding-left: ${px(5)};
`

// Footer
const Footer = styled.View`
    pointer-events: box-none;
    bottom: 0;
    width: 100%;
    height: ${px(CARD_SIZE.HEIGHT * DECK_SCALE)};
    position: absolute;
    justify-content: center;
    align-items: center;
`

const FooterContent = styled.View`
    pointer-events: box-none;
    position: absolute;
    padding: 0 ${px(PADDING.SIDES / 2)};
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
`
const FooterConfirmCancel = styled.View`
    pointer-events: box-none;
    position: absolute;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const FooterLeft = styled.View`
    margin-right: ${px(20)};
    width: ${px(BUTTON_HEIGHT)};
`
const FooterCenter = styled.View`
    bottom: ${px(-40)};
    flex: 1;
    align-items: center;
    pointer-events: box-none;
`
const FooterRight = styled.View`
    margin-left: ${px(20)};
    width: ${px(BUTTON_WIDTH)};
`
const FooterConfirmCancelButton = styled.View`
    margin: 0 ${px(20)};
`

const CardSelected = styled.View`
    pointer-events: box-none;
    position: absolute;
    bottom: ${px(50)};
    right: ${px(100)};
    align-items: flex-end;
`
const CardSelectedContent = styled.View`
    pointer-events: none;
    background: ${COLOR.BROWNBLACK};
    width: ${px(500)};
    min-height: ${px(200)};
    border-radius: ${px(10)};
    padding: ${px(15)} ${px(15)} ${px(30)} ${px(150)};
`
const CardSelectedCard = styled.View`
    position: absolute;
    left: ${px(-50)};
    top: ${px(-20)};
    height: ${px(CARD_SIZE.HEIGHT)};
    width: ${px(CARD_SIZE.WIDTH)};
    transform: rotate(-5deg);
`
const CardSelectedButton = styled.View`
    margin-top: ${px(10)};
`
const CardSelectedTitle = styled.Text`
    font-size: ${px(22)};
    font-family: Poppins-Black;
    color: white;
`
const CardSelectedDescription = styled.Text`
    font-size: ${px(20)};
    font-family: Poppins-Medium;
    color: white;
`

const CardActivation = styled.View`
    pointer-events: none;
    overflow: hidden;
    background: ${COLOR.BLUEDARK + '66'};
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const CardActivationMessage = styled.View`
    top: ${px(-100)};
`

// Recruit
const RecruitBackground = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${COLOR.BLUEBLACK + 'EE'};
    align-items: center;
    justify-content: center;
`
const RecruitUnits = styled.View`
    flex-direction: row;
    padding-bottom: ${px(50)};
`
const RecruitUnitContainer = styled.View`
    width: ${px(250)};
    height: ${px(386)};
    margin: 0 ${px(20)};
    position: relative;
`
const RecruitUnit = styled.ImageBackground`
    width: 100%;
    height: 100%;
`
const RecruitButton = styled.TouchableOpacity`
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: ${px(-25)};
`

const RecruitCounters = styled.View`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 ${px(10)};
    bottom: ${px(-80)};
`
const RecruitCountersList = styled.View`
    display: flex;
    flex-direction: row;
`
const RecruitCountersUnit = styled.View`
    background: ${COLOR.BROWNBLACK};
    border: ${px(3)} solid ${(p) => (p.counter ? COLOR.RED : COLOR.GREEN)};
    width: ${px(50)};
    height: ${px(50)};
    margin: 0 ${px(2)};
    padding: ${px(5)};
    border-radius: 50%;
`
const RecruitCountersIcon = styled.Image`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`

const CloseContainer = styled.TouchableOpacity`
    position: absolute;
    right: ${px(30)};
    top: ${px(30)};
    z-index: 1;
`
const Close = styled.Image`
    width: ${px(100)};
    height: ${px(100)};
    transform: rotate(15deg);
`
